<script setup lang="ts">
import {
  getOrganizationAssetsHierarchyLegacy,
  OrganizationAssetsHierarchyResponseLegacy,
} from '@/api/assets';
import { UUID } from '@/api/common';
import {
  AvailableSafetyEventType,
  getAvailableSafetyEvents,
  getTripEventsSummaryByAsset,
  getTripEventsSummaryByAssetForTwoHoursBuckets,
  getTripEventsSummaryByTime,
  getTripEventsSummaryByTimeForTwoHoursBuckets,
  GetTripsWithEventsSummary2HoursDetails,
  SafetyEventType,
} from '@/api/trip';
import { useActiveContext } from '@/auth/context';
import MultiSelectDropDown from '@/components/form/MultiSelectDropDown.vue';
import PeriodSelector from '@/components/form/PeriodSelector.vue';
import HorizontalAverageSeverityStackedBarChart from '@/components/kpiCharts/HorizontalAverageSeverityStackedBarChart.vue';
import VerticalStackedBarChart from '@/components/kpiCharts/VerticalStackedBarChart.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import { useAsync } from '@/composables/async';
import { useUnitConversion } from '@/composables/conversion';
import { useRoute } from '@/composables/router';
import i18n from '@/lang';
import router from '@/router';
import {
  AGGREGATION_TYPE_ROUTE_QUERY_PARAM,
  ASSET_SAFETY_CODE,
  DATE_PERIOD_ROUTE_QUERY_PARAM,
  END_DATE_ROUTE_QUERY_PARAM,
  expandedSingleAssetSafetyLocation,
  NEW_FLEET_SAFETY_CODE,
  SAFETY_EVENT_ROUTE_QUERY_PARAM,
  START_DATE_ROUTE_QUERY_PARAM,
} from '@/router/links/safetyWidget';
import { UserModule } from '@/store/modules/user';
import { AssetType } from '@/utils/assetTypes';
import { max } from '@/utils/math';
import { never } from '@/utils/promise';
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterOfTheWorldsInString,
} from '@/utils/string';
import {
  DEFAULT_PERIOD_RANGE,
  getDateRangeForADatePeriod,
  getDateRangeFromStartAndEnd,
} from '@/utils/time';
import { DatePeriod, PeriodRange } from '@/utils/types/date';
import { Option } from '@/utils/types/option';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { AggregationType } from '@/widgets/utils/Constants';
import {
  computeTripSafetyStatisticsForTwoHoursBuckets,
  defaultSafetyWidgetTitle,
  flattenOrganizationHierarchyAssets,
  formatSeverityStatistic,
  getLabelWithEvent,
  getLabelWithoutEvent,
  getStatisticLabelTranslated,
  isAssetSelection,
  isDateSelection,
  TripEventSummaries,
  TripSafetyStatisticsForTwoHoursBuckets,
  TripsSummariesChartSelection,
} from '@/widgets/utils/tripSafety';
import { computed, ref, unref, watch } from 'vue';
import { Location } from 'vue-router';

const activeContext = useActiveContext();

const route = useRoute();

const aggregationButtons = [
  {
    id: AggregationType.AggregateByTime,
    label: i18n.tc('newFleetSafety.time'),
  },
  {
    id: AggregationType.AggregateByAsset,
    label: i18n.tc('newFleetSafety.asset'),
  },
];

/** Aggregation selection */
const selectedAggregationType = computed((): AggregationType => {
  const query = unref(route).query;
  const aggregationType = query[AGGREGATION_TYPE_ROUTE_QUERY_PARAM];

  if (typeof aggregationType !== 'string')
    return AggregationType.AggregateByTime;

  return aggregationType as AggregationType;
});

function updateAggregationType(newAggregationType: AggregationType): void {
  router.replace({
    query: {
      ...route.value.query,
      [AGGREGATION_TYPE_ROUTE_QUERY_PARAM]: newAggregationType,
    },
  });
}

/** Period selection */
const selectedPeriodRange = computed((): PeriodRange => {
  const query = unref(route).query;
  const startDate = query[START_DATE_ROUTE_QUERY_PARAM];
  const endDate = query[END_DATE_ROUTE_QUERY_PARAM];
  const datePeriod = query[DATE_PERIOD_ROUTE_QUERY_PARAM];

  if (typeof startDate !== 'string' || typeof endDate !== 'string') {
    return DEFAULT_PERIOD_RANGE;
  }

  return {
    ...getDateRangeFromStartAndEnd(startDate, endDate),
    datePeriod: datePeriod ? (datePeriod as DatePeriod) : DatePeriod.DATP_DAY,
  };
});

/** Current (left side) horizontal chart data selection in LOCALDATE_FORMAT (i.e. yyyy-mm-dd) or asset UUID */
const tripsSummariesChartSelection = ref<
  TripsSummariesChartSelection | undefined
>(undefined);

function updateDataSelectionFromChart(
  newData: TripsSummariesChartSelection | undefined
): void {
  tripsSummariesChartSelection.value = newData;
}

// Unset selection if the aggregation type changes
watch(selectedAggregationType, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    updateDataSelectionFromChart(undefined);
  }
});

/* --------------- Available safety events by company type --------------- */

const availableSafetyEventTypes = useAsync(
  computed(
    async (): Promise<AvailableSafetyEventType[]> =>
      getAvailableSafetyEvents(UserModule.companyType, unref(activeContext))
  )
);

const { currentUserPreferredUnit, currentUserConvertNumberMany } =
  useUnitConversion();

// Select url safety event type if applicable or default event type after loading available event types for this company type.
const selectedEventType = computed((): SafetyEventType | undefined => {
  const availableSafetyEvents = unref(availableSafetyEventTypes).data;

  const query = unref(route).query;
  const safetyEvent = query[SAFETY_EVENT_ROUTE_QUERY_PARAM];
  if (
    safetyEvent &&
    availableSafetyEvents?.some((event) => event.eventTypeCode === safetyEvent)
  ) {
    return safetyEvent as SafetyEventType;
  }

  if (!availableSafetyEvents) return;

  return availableSafetyEvents.find(
    (availableEventType) => availableEventType.isDefault
  )?.eventTypeCode;
});

const selectedEvent = computed(() => {
  const availableEvents = unref(availableSafetyEventTypes).data;

  if (availableEvents && unref(selectedEventType)) {
    return availableEvents.find(
      (item) => item.eventTypeCode === unref(selectedEventType)
    );
  }
});

function handleSelectedEventUpdate(newSafetyEvent: SafetyEventType): void {
  router.replace({
    query: {
      ...route.value.query,
      [SAFETY_EVENT_ROUTE_QUERY_PARAM]: newSafetyEvent,
    },
  });
}

/* --------------- Available organization hierarchy assets for selected organization --------------- */

const availableOrganizationHierarchyAssets = useAsync(
  computed(
    async (): Promise<OrganizationAssetsHierarchyResponseLegacy> =>
      getOrganizationAssetsHierarchyLegacy(
        AssetType.TippingVehicle,
        unref(activeContext).organization?.id,
        unref(activeContext)
      )
  )
);

const selectedAssets = ref<UUID[]>();

function updateSelectedAssets(newSelection: UUID[]): void {
  const availableAssets = unref(availableAssetsForFiltering).data;
  const isAllAssets =
    !availableAssets || availableAssets.length === newSelection.length;
  selectedAssets.value = isAllAssets
    ? availableAssetsForFiltering.value.data?.map((asset) => asset.key)
    : newSelection;
}

const availableAssetsForFiltering = useAsync(
  computed(async (): Promise<Option[]> => {
    const organizationHierarchy = unref(
      availableOrganizationHierarchyAssets
    ).data;

    if (organizationHierarchy === undefined) {
      return never();
    }

    const allTippingAssets = flattenOrganizationHierarchyAssets(
      organizationHierarchy
    );

    const finalResult = allTippingAssets.map(
      (initialAsset): Option => ({
        key: initialAsset.id,
        label: initialAsset.companyAssetId,
      })
    );

    selectedAssets.value = finalResult.map((asset) => asset.key);

    return finalResult;
  })
);

/* --------------- Trips events summaries by time or events summaries by asset --------------- */

const tripEventSummaries = useAsync(
  computed(async (): Promise<TripEventSummaries> => {
    const eventType = unref(selectedEventType);
    const multiAssetSelection = unref(selectedAssets);
    const periodRange = unref(selectedPeriodRange);
    const aggregationType = unref(selectedAggregationType);
    const organizationIds = unref(activeContext).organizationIds;

    if (!eventType) {
      return never();
    }

    const isAllAssets =
      availableAssetsForFiltering.value.data?.length ===
      selectedAssets.value?.length;

    // If no assets are selected, we can't request anything from the
    // backend, and we want to explicitly show 'no data' to
    // the user.
    if (multiAssetSelection?.length === 0) {
      return {
        aggregationType,
        summaries: [],
      };
    }

    if (aggregationType === AggregationType.AggregateByTime) {
      return {
        aggregationType,
        summaries: await getTripEventsSummaryByTime(
          {
            assetUUIDs: !isAllAssets ? multiAssetSelection : undefined,
            organizationUUIDs: isAllAssets ? organizationIds : undefined,
            selectedDatePeriod: periodRange.datePeriod,
            eventTypeCode: eventType,
            datePeriodStartDate: periodRange.start,
            datePeriodEndDate: periodRange.endExclusive,
            assetTypeCode: AssetType.TippingVehicle,
          },
          unref(activeContext)
        ),
      };
    }

    return {
      aggregationType,
      summaries: await getTripEventsSummaryByAsset(
        {
          assetUUIDs: !isAllAssets ? multiAssetSelection : undefined,
          organizationUUIDs: isAllAssets ? organizationIds : undefined,
          selectedDatePeriod: periodRange.datePeriod,
          eventTypeCode: eventType,
          datePeriodStartDate: periodRange.start,
          datePeriodEndDate: periodRange.endExclusive,
          assetTypeCode: AssetType.TippingVehicle,
        },
        unref(activeContext)
      ),
    };
  })
);

/* --------------- Trips events summaries by time or events summaries by asset for two hours buckets --------------- */

async function fetchTripsWithEventsSummary2HoursDetails(
  selection: TripsSummariesChartSelection | undefined
): Promise<GetTripsWithEventsSummary2HoursDetails> {
  const eventType = unref(selectedEventType);
  const multiAssetSelection = unref(selectedAssets);
  const periodRange = unref(selectedPeriodRange);
  const aggregationType = unref(selectedAggregationType);
  const organizationIds = unref(activeContext).organizationIds;
  const availableAssets = unref(availableAssetsForFiltering).data;
  const availableAssetsUUIDs = availableAssets?.map((asset) => asset.key);

  if (!eventType) {
    return never();
  }

  // If no assets are selected, we can't request anything from the
  // backend, and we want to explicitly show 'no data' to
  // the user.
  if (multiAssetSelection?.length === 0 || availableAssetsUUIDs?.length === 0) {
    return {
      numberOfAssetsWithTrips: 0,
      tripsWithEventsDetailedSummary: [],
    };
  }

  const inUseAssetSelection =
    aggregationType === AggregationType.AggregateByAsset &&
    isAssetSelection(selection)
      ? selection?.selectedAsset
      : multiAssetSelection;
  const inUsePeriodSelection =
    aggregationType === AggregationType.AggregateByTime &&
    isDateSelection(selection)
      ? getDateRangeForADatePeriod(periodRange, selection?.selectedDate)
      : periodRange;

  const isAllAssets =
    aggregationType === AggregationType.AggregateByAsset &&
    availableAssetsForFiltering.value.data?.length ===
      inUseAssetSelection?.length;

  if (aggregationType === AggregationType.AggregateByTime) {
    return getTripEventsSummaryByTimeForTwoHoursBuckets(
      {
        assetUUIDs: !isAllAssets ? multiAssetSelection : undefined,
        organizationUUIDs: isAllAssets ? organizationIds : undefined,
        selectedDatePeriod: periodRange.datePeriod,
        eventTypeCode: eventType,
        datePeriodStartDate: inUsePeriodSelection.start,
        datePeriodEndDate: inUsePeriodSelection.endExclusive,
        assetTypeCode: AssetType.TippingVehicle,
      },
      unref(activeContext)
    );
  }

  return getTripEventsSummaryByAssetForTwoHoursBuckets(
    {
      assetUUIDs: !isAllAssets ? inUseAssetSelection : undefined,
      organizationUUIDs: isAllAssets ? organizationIds : undefined,
      selectedDatePeriod: periodRange.datePeriod,
      eventTypeCode: eventType,
      datePeriodStartDate: inUsePeriodSelection.start,
      datePeriodEndDate: inUsePeriodSelection.endExclusive,
      assetTypeCode: AssetType.TippingVehicle,
    },
    unref(activeContext)
  );
}

const tripEventSummariesForTwoHoursBucketsAll = useAsync(
  computed((): Promise<GetTripsWithEventsSummary2HoursDetails> => {
    return fetchTripsWithEventsSummary2HoursDetails(undefined);
  })
);

const tripEventSummariesForTwoHoursBuckets = useAsync(
  computed(async (): Promise<GetTripsWithEventsSummary2HoursDetails> => {
    const selection = unref(tripsSummariesChartSelection);
    // if (!selection) {
    //   return unref(tripEventSummariesForTwoHoursBucketsAll).data ?? never();
    // }
    return fetchTripsWithEventsSummary2HoursDetails(selection);
  }),
  { clearOnRefresh: true }
);

const maximumSeverityLevel = computed((): number | undefined => {
  const allBuckets = unref(tripEventSummariesForTwoHoursBucketsAll).data;
  if (!allBuckets) {
    return undefined;
  }
  return allBuckets.tripsWithEventsDetailedSummary
    .flatMap((summary) => summary.eventsDetailedSummary.maximumSeverityLevel)
    .reduce(max, -Infinity);
});

/* --------------- Trip events statistics (bottom side of the charts) --------------- */

const tripEventStatistics = computed(
  (): TripSafetyStatisticsForTwoHoursBuckets | undefined => {
    const selectedTrips = unref(tripEventSummariesForTwoHoursBuckets).data;
    if (!selectedTrips || !selectedEvent.value) {
      return undefined;
    }

    const rawStatistics =
      computeTripSafetyStatisticsForTwoHoursBuckets(selectedTrips);

    return currentUserConvertNumberMany(
      rawStatistics,
      ['minSeverity', 'avgSeverity', 'maxSeverity'],
      selectedEvent.value.unitCode
    );
  }
);

function updateSelectedPeriodRange(newPeriodRange: PeriodRange): void {
  router.replace({
    query: {
      ...route.value.query,
      [START_DATE_ROUTE_QUERY_PARAM]: newPeriodRange.start,
      [END_DATE_ROUTE_QUERY_PARAM]: newPeriodRange.end,
      [DATE_PERIOD_ROUTE_QUERY_PARAM]: newPeriodRange.datePeriod,
    },
  });
}

function handleVerticalStackedBarsClick(
  selection: TripsSummariesChartSelection | undefined
): void {
  updateDataSelectionFromChart(selection);
}

/**
 * Compute if current grouping scope (fleet/asset) has:
 * - one safety event then: Fleet/Asset <event> e.g: Fleet Overload for TruckOM customer type
 * - has more safety events then initial translation title: "Fleet/Asset Safety" e.g: ("Fleet Safety" or "Asset Safety")
 */
const defaultWidgetTitle = computed((): string => {
  const availableEvents = unref(availableSafetyEventTypes).data;
  const isAssetScope = unref(route)?.params?.id ? true : false;
  if (
    availableEvents &&
    availableEvents?.length === 1 &&
    availableEvents?.[0]?.eventTypeCode
  ) {
    const finalResult = defaultSafetyWidgetTitle(
      availableEvents?.[0]?.eventTypeCode,
      isAssetScope
    );

    return capitalizeFirstLetterOfTheWorldsInString(finalResult);
  }

  return isAssetScope
    ? i18n.tc(ASSET_SAFETY_CODE)
    : i18n.tc(NEW_FLEET_SAFETY_CODE);
});

function getSingleAssetLocation(assetUUID: UUID): Location {
  return expandedSingleAssetSafetyLocation(
    assetUUID,
    unref(selectedEventType)!,
    unref(selectedPeriodRange),
    route.value
  );
}
</script>

<template>
  <WidgetCard
    :default-title="defaultWidgetTitle"
    :title-is-loading="availableSafetyEventTypes.loading"
    :expandable="true"
    v-bind:loading="availableSafetyEventTypes.loading"
  >
    <template #actions>
      <el-select
        v-model="selectedEventType"
        class="select-safety-event-types"
        @change="handleSelectedEventUpdate"
      >
        <el-option
          v-for="item in availableSafetyEventTypes.data ?? []"
          :key="item.eventTypeCode"
          :label="capitalizeFirstLetter($tc(item.eventTypeCode))"
          :value="item.eventTypeCode"
        />
      </el-select>

      <MultiSelectDropDown
        v-loading="availableAssetsForFiltering.loading"
        :filter-label="$t('selectAssets')"
        :options="availableAssetsForFiltering.data ?? []"
        :selected-options="selectedAssets ?? []"
        @change="updateSelectedAssets"
      />

      <PeriodSelector
        class="period-selector"
        :expanded="true"
        @select="updateSelectedPeriodRange"
        :customizable="true"
        :dropDownIsDisabled="false"
        :customDate="selectedPeriodRange"
      />
      <div class="aggregation-selector">
        <!-- Note: We are using @input instead of @change due to a bug in el-radio-group -->
        <el-radio-group
          :value="selectedAggregationType"
          fill="gray"
          size="small"
          class="radio_area"
          @input="updateAggregationType"
        >
          <el-radio-button
            border
            style="margin: 0"
            v-for="option in aggregationButtons"
            :label="option.id"
            :key="option.id"
          >
            {{ option.label }}
          </el-radio-button>
        </el-radio-group>
      </div>
    </template>
    <div class="new-fleet-safety-expanded-widget">
      <div class="main-container">
        <div class="charts-main-container">
          <div
            class="trips-summaries-chart-container"
            v-loading="tripEventSummaries.loading"
          >
            <VerticalStackedBarChart
              :isInExpandedMode="true"
              :safetyEvent="selectedEventType"
              :data="tripEventSummaries.data"
              :aggregationType="selectedAggregationType"
              @selection="handleVerticalStackedBarsClick"
              :selectedDate="
                isDateSelection(tripsSummariesChartSelection)
                  ? tripsSummariesChartSelection?.selectedDate
                  : undefined
              "
              :getSingleAssetLocation="getSingleAssetLocation"
            />
          </div>
          <div class="horizontal-severity-percentage-stacked-bar-chart">
            <HorizontalAverageSeverityStackedBarChart
              v-loading="tripEventSummariesForTwoHoursBuckets.loading"
              :data="tripEventSummariesForTwoHoursBuckets.data"
              :safetyEvent="selectedEventType"
              :maximumSeverityLevel="maximumSeverityLevel"
              :safetyUnitCode="selectedEvent?.unitCode"
            />
          </div>
        </div>
      </div>

      <table
        v-if="selectedEventType && selectedEvent"
        class="new-statistics-table"
      >
        <thead>
          <tr>
            <th class="header-cell">
              {{ i18n.tc('newFleetSafety.totalAssets') }}
            </th>
            <th class="header-cell">
              {{ i18n.tc('newFleetSafety.totalTrips') }}
            </th>
            <th class="header-cell">
              {{ getLabelWithEvent(selectedEventType) }}
            </th>
            <th class="header-cell">
              {{ getLabelWithoutEvent(selectedEventType) }}
            </th>
            <th class="header-cell">
              {{ i18n.tc('newFleetSafety.simpleRatio') }}
            </th>
            <th class="header-cell">
              {{
                getStatisticLabelTranslated(
                  'min',
                  selectedEventType,
                  currentUserPreferredUnit(selectedEvent?.unitCode)
                )
              }}
            </th>
            <th class="">
              {{
                getStatisticLabelTranslated(
                  'avg',
                  selectedEventType,
                  currentUserPreferredUnit(selectedEvent?.unitCode)
                )
              }}
            </th>
            <th class="">
              {{
                getStatisticLabelTranslated(
                  'max',
                  selectedEventType,
                  currentUserPreferredUnit(selectedEvent?.unitCode)
                )
              }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {{ tripEventStatistics?.totalAssets }}
            </td>
            <td>
              {{ tripEventStatistics?.totalTrips }}
            </td>
            <td>
              {{ tripEventStatistics?.tripsWithEvents }}
            </td>
            <td>
              {{ tripEventStatistics?.tripsWithoutEvents }}
            </td>
            <td>
              {{
                formatSeverityStatistic(
                  tripEventStatistics?.ratio,
                  KPI_UNIT.Percentage
                )
              }}
            </td>
            <td>
              {{
                formatSeverityStatistic(
                  tripEventStatistics?.minSeverity,
                  currentUserPreferredUnit(selectedEvent?.unitCode)
                )
              }}
            </td>
            <td>
              {{
                formatSeverityStatistic(
                  tripEventStatistics?.avgSeverity,
                  currentUserPreferredUnit(selectedEvent?.unitCode)
                )
              }}
            </td>
            <td>
              {{
                formatSeverityStatistic(
                  tripEventStatistics?.maxSeverity,
                  currentUserPreferredUnit(selectedEvent?.unitCode)
                )
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </WidgetCard>
</template>

<style scoped>
.period-selector :deep(.time-selector) {
  width: 300px !important;
}
</style>

<style lang="scss" scoped>
.new-fleet-safety-expanded-widget {
  height: 100%;
  overflow: hidden;

  .charts-main-container {
    display: flex;

    .trips-summaries-chart-container {
      flex: 1;
      border-top: 1px solid lightgray;
      border-bottom: 1px solid lightgray;
      padding: 13px;
    }

    .horizontal-severity-percentage-stacked-bar-chart {
      height: 100%;
      width: 40%;
      padding: 13px;
      border-top: 1px solid lightgray;
      border-left: 1px solid lightgray;
      border-bottom: 1px solid lightgray;
    }
  }
}

/** Adjust main charts container height by resolution size: for small rezolutions */
@media screen and (max-width: 1756px) {
  .charts-main-container {
    height: 323px;
  }

  .select-safety-event-types {
    margin-right: 20px;
  }

  .select-asset-dropdown {
    margin-right: 20px;
  }
}

/** Adjust main charts container height by resolution size: for big rezolutions */
@media screen and (min-width: 1756px) {
  .charts-main-container {
    height: 538px;
  }

  .select-safety-event-types,
  .select-asset-dropdown,
  .period-selector {
    margin-right: 35px;
  }

  .aggregation-selector {
    margin-left: -10px;
  }
}

.new-statistics-table {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: Roboto;
  padding: 10px;
}
</style>
