var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('WidgetCard',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.registerAssetViewIsLoading),expression:"registerAssetViewIsLoading"}],attrs:{"showBackButton":true,"floatActionsRight":true,"defaultTitle":_vm.cardTitle},on:{"click":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(
          _vm.registerAssetInfo.connectionStatus ===
          _vm.REGISTERED_ASSET_CONNECTION_STATUSES.AssconbRegistered
        )?_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:([
          _vm.ALL_CLAIMS_CODES.AUTHRSC_ACTION_REGISTERED_ASSET_DELETE,
        ]),expression:"[\n          ALL_CLAIMS_CODES.AUTHRSC_ACTION_REGISTERED_ASSET_DELETE,\n        ]"}],attrs:{"id":"delete_registered_asset","type":"plain"},on:{"click":function($event){_vm.isDeleteDialogOpen = true}}},[_c('i',{staticClass:"el-icon-minus common-icon"}),_vm._v(_vm._s(_vm.$t('common.delete'))+" ")]):_vm._e(),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:([
          _vm.ALL_CLAIMS_CODES.AUTHRSC_ACTION_REGISTERED_ASSET_UPDATE,
        ]),expression:"[\n          ALL_CLAIMS_CODES.AUTHRSC_ACTION_REGISTERED_ASSET_UPDATE,\n        ]"}],attrs:{"id":"edit_registered_asset","type":"plain"},on:{"click":_vm.gotoEditRegisteredAsset}},[_c('i',{staticClass:"el-icon-edit common-icon"}),_vm._v(_vm._s(_vm.$t('common.edit'))+" ")])]},proxy:true}])},[_c('div',{staticStyle:{"margin-left":"40px","margin-top":"44px"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":10}},[_c('general-info',{attrs:{"id":"register_detail_view_asset_HIN","name":'assetMgmt.AssetHIN',"value":_vm.registerAssetInfo.assetDataCollectionId}}),_c('general-info',{attrs:{"id":"register_detail_view_info_asset_type","name":'assetMgmt.assetType',"value":_vm.$t(_vm.registerAssetInfo.assetType)}}),_c('general-info',{attrs:{"id":"register_detail_view_info_model_code","name":'assetMgmt.prodModel',"value":_vm.registerAssetInfo.productModelCode}}),_c('general-info',{attrs:{"id":"register_detail_view_info_model_number","name":'assetMgmt.modelNumber',"value":_vm.registerAssetInfo.productModelNumber}}),(
              _vm.formFieldState.SerialNumber.type !==
              _vm.assetFieldStateType.NotApplicable
            )?_c('general-info',{attrs:{"id":"register_detail_view_serial_number","name":'assetMgmt.assetSerialNumber',"value":_vm.registerAssetInfo.serialNumber}}):_vm._e(),(
              _vm.formFieldState.VIN.type !== _vm.assetFieldStateType.NotApplicable
            )?_c('general-info',{attrs:{"name":'assetMgmt.VIN',"value":_vm.registerAssetInfo.vinNumber}}):_vm._e(),(
              _vm.formFieldState.AssetId.type !==
              _vm.assetFieldStateType.NotApplicable
            )?_c('general-info',{attrs:{"name":'assetMgmt.preliminaryAssetId',"value":_vm.registerAssetInfo.preliminaryAssetId}}):_vm._e(),_c('general-info',{attrs:{"id":"register_detail_view_info_service_region","name":'common.serviceRegion',"value":_vm.registerAssetInfo.serviceRegion}}),_c('general-info',{attrs:{"id":"register_detail_view_info_service_region","name":'common.hyvaRegion',"value":_vm.registerAssetInfo.hyvaRegion}}),_c('general-info',{attrs:{"id":"register_detail_view_info_manufacture_date","name":'assetMgmt.manufactureDate',"value":_vm.registerAssetInfo.manufactureDate}}),_c('general-info',{attrs:{"id":"register_detail_view_info_controller_id","name":'assetMgmt.controllerId',"value":_vm.registerAssetInfo.controllerId}})],1)],1)],1)]),_c('WidgetDialog',{attrs:{"visible":_vm.isDeleteDialogOpen,"title":_vm.$t(_vm.ALL_CLAIMS_CODES.AUTHRSC_ACTION_REGISTERED_ASSET_DELETE),"confirmBtnName":_vm.$t('common.delete'),"width":"40%"},on:{"handle-cancel":function($event){_vm.isDeleteDialogOpen = false},"handle-confirm":_vm.removeAsset}},[_c('div',{staticClass:"dialog-container"},[_c('i18n',{staticClass:"main-text",attrs:{"path":"assets.deleteRegisteredAssetConfirmation","tag":"div"}},[_c('span',{staticClass:"asset-name",attrs:{"place":"companyAssetId"}},[_vm._v(_vm._s(_vm.registerAssetInfo?.preliminaryAssetId ?? ''))])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }