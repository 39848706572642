<script lang="ts">
import { deleteAsset } from '@/api/assets';
import { getAssetOnBoardingById } from '@/api/assetsMgmt';
import { LoggedInUser, useLoggedInUser } from '@/auth/user';
import WidgetDialog from '@/components/dialog/WidgetDialog.vue';
import GeneralInfo from '@/components/form/GeneralInfo.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import NavPage from '@/components/nav/NavPage.vue';
import {
  AssetField,
  AssetFieldStateType,
  AssetFieldToState,
  AssetStage,
  AssetType,
  getAssetFieldStates,
} from '@/utils/assetTypes';
import { customFailedMessage, customSuccessMessage } from '@/utils/prompt';
import {
  fakeUnref,
  InitializeReactive,
} from '@/utils/vueClassComponentHelpers';
import {
  ALL_CLAIMS_CODES,
  REGISTERED_ASSET_CONNECTION_STATUSES,
} from '@/utils/workData/lookuptable';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { formatServerDate } from '../helper';

@Component({
  name: 'RegisterAssetView',
  components: {
    NavPage,
    'general-info': GeneralInfo,
    WidgetCard,
    WidgetDialog,
  },
})
export default class extends Vue {
  ALL_CLAIMS_CODES = ALL_CLAIMS_CODES;
  REGISTERED_ASSET_CONNECTION_STATUSES = REGISTERED_ASSET_CONNECTION_STATUSES;

  @Prop({ required: true }) assetId!: string;

  /** Local variables */

  assetFieldStateType = AssetFieldStateType;
  registerAssetInfo: any = {};
  registerAssetViewIsLoading: boolean = false;
  isDeleteDialogOpen: boolean = false;

  @InitializeReactive
  loggedInUser!: LoggedInUser | undefined;

  mounted() {
    this.loggedInUser = fakeUnref(useLoggedInUser());
    this.fetchAssetDetail();
  }

  async fetchAssetDetail() {
    this.registerAssetViewIsLoading = true;
    await getAssetOnBoardingById(this.assetId).then((res) => {
      if (res.code === 200) {
        this.registerAssetInfo = res.data;
        this.registerAssetInfo.manufactureDate = formatServerDate(
          res.data.manufactureDate
        );
      } else {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      this.registerAssetViewIsLoading = false;
    });
  }

  get cardTitle(): string | undefined {
    return this.formFieldState[AssetField.SerialNumber].type ===
      AssetFieldStateType.NotApplicable
      ? this.registerAssetInfo.assetDataCollectionId
      : `${this.registerAssetInfo.assetDataCollectionId}/${this.registerAssetInfo.serialNumber}`;
  }

  get formFieldState(): AssetFieldToState {
    const assetType = this.registerAssetInfo.assetType as AssetType;

    return getAssetFieldStates(AssetStage.Register, assetType);
  }

  gotoEditRegisteredAsset() {
    this.$router.push(`/asset-mgmt/register/edit/${this.assetId}`);
  }

  async removeAsset() {
    try {
      await deleteAsset(this.registerAssetInfo.id);
      customSuccessMessage(this.$t('assets.deleteSuccess'));
      this.isDeleteDialogOpen = false;
      this.$router.back();
    } catch (e) {
      customFailedMessage(this.$t('common.unexpectedError'));
      console.error(e);
    }
  }
}
</script>

<template>
  <div>
    <WidgetCard
      v-loading="registerAssetViewIsLoading"
      :showBackButton="true"
      :floatActionsRight="true"
      :defaultTitle="cardTitle"
      @click="$router.back()"
    >
      <template #actions>
        <el-button
          id="delete_registered_asset"
          type="plain"
          v-if="
            registerAssetInfo.connectionStatus ===
            REGISTERED_ASSET_CONNECTION_STATUSES.AssconbRegistered
          "
          v-permission="[
            ALL_CLAIMS_CODES.AUTHRSC_ACTION_REGISTERED_ASSET_DELETE,
          ]"
          @click="isDeleteDialogOpen = true"
        >
          <i class="el-icon-minus common-icon" />{{ $t('common.delete') }}
        </el-button>
        <el-button
          id="edit_registered_asset"
          type="plain"
          v-permission="[
            ALL_CLAIMS_CODES.AUTHRSC_ACTION_REGISTERED_ASSET_UPDATE,
          ]"
          @click="gotoEditRegisteredAsset"
        >
          <i class="el-icon-edit common-icon" />{{ $t('common.edit') }}
        </el-button>
      </template>

      <div style="margin-left: 40px; margin-top: 44px">
        <el-row :gutter="20">
          <el-col :span="10">
            <general-info
              id="register_detail_view_asset_HIN"
              :name="'assetMgmt.AssetHIN'"
              :value="registerAssetInfo.assetDataCollectionId"
            />
            <general-info
              id="register_detail_view_info_asset_type"
              :name="'assetMgmt.assetType'"
              :value="$t(registerAssetInfo.assetType)"
            />
            <general-info
              id="register_detail_view_info_model_code"
              :name="'assetMgmt.prodModel'"
              :value="registerAssetInfo.productModelCode"
            />
            <general-info
              id="register_detail_view_info_model_number"
              :name="'assetMgmt.modelNumber'"
              :value="registerAssetInfo.productModelNumber"
            />
            <general-info
              v-if="
                formFieldState.SerialNumber.type !==
                assetFieldStateType.NotApplicable
              "
              id="register_detail_view_serial_number"
              :name="'assetMgmt.assetSerialNumber'"
              :value="registerAssetInfo.serialNumber"
            />
            <general-info
              v-if="
                formFieldState.VIN.type !== assetFieldStateType.NotApplicable
              "
              :name="'assetMgmt.VIN'"
              :value="registerAssetInfo.vinNumber"
            />
            <general-info
              v-if="
                formFieldState.AssetId.type !==
                assetFieldStateType.NotApplicable
              "
              :name="'assetMgmt.preliminaryAssetId'"
              :value="registerAssetInfo.preliminaryAssetId"
            />
            <general-info
              id="register_detail_view_info_service_region"
              :name="'common.serviceRegion'"
              :value="registerAssetInfo.serviceRegion"
            />
            <general-info
              id="register_detail_view_info_service_region"
              :name="'common.hyvaRegion'"
              :value="registerAssetInfo.hyvaRegion"
            />
            <general-info
              id="register_detail_view_info_manufacture_date"
              :name="'assetMgmt.manufactureDate'"
              :value="registerAssetInfo.manufactureDate"
            />
            <general-info
              id="register_detail_view_info_controller_id"
              :name="'assetMgmt.controllerId'"
              :value="registerAssetInfo.controllerId"
            />
          </el-col>
        </el-row>
      </div>
    </WidgetCard>
    <WidgetDialog
      :visible="isDeleteDialogOpen"
      :title="$t(ALL_CLAIMS_CODES.AUTHRSC_ACTION_REGISTERED_ASSET_DELETE)"
      :confirmBtnName="$t('common.delete')"
      width="40%"
      @handle-cancel="isDeleteDialogOpen = false"
      @handle-confirm="removeAsset"
    >
      <div class="dialog-container">
        <i18n
          path="assets.deleteRegisteredAssetConfirmation"
          tag="div"
          class="main-text"
        >
          <!-- Need to prevent whitespace around the span, to prevent unfortunate wrapping
               when the asset ID is ~12 chars long. See DP-3194.
            -->
          <!-- prettier-ignore -->
          <span class="asset-name" place="companyAssetId">{{ registerAssetInfo?.preliminaryAssetId ?? '' }}</span>
        </i18n>
      </div>
    </WidgetDialog>
  </div>
</template>

<style lang="scss" scoped>
:deep(.form-item-container) {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 40px;
  .form-title {
    width: 270px;
    margin-right: 22px;
    text-align: left;
    font-family: $font-Roboto-Regular;
    color: #373e41;
  }
  .show-title {
    font-size: 18px;
  }
}

.dialog-container {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 57px 0;
  font-size: 22px;
}

.asset-name {
  color: #ffcd00;
  font-weight: bold;
}
</style>
