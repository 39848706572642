import { ContextType } from '@/auth/contextType';
import Layout from '@/components/layout/Layout.vue';
import { AssetType } from '@/utils/assetTypes';
import { ALL_CLAIMS_CODES } from '@/utils/workData/lookuptable';
import { RouteConfig } from 'vue-router';

export enum OperationalDashboardRouteNames {
  OperationalDashboard = 'OperationalDashboard',
  TippingVehicles = 'OperationalDashboardTippingVehicles',
  TippingVehicleById = 'OperationalDashboardTippingVehicleInfo',
  OperationalDashboardTippingVehicleCreatePreset = 'OperationalDashboardTippingVehicleCreatePreset',
  OperationalDashboardTippingVehicleApplyPreset = 'OperationalDashboardTippingVehicleApplyPreset',
}

export interface OverviewOperationalDashboardRouteName {
  all: OperationalDashboardRouteNames;
  single: OperationalDashboardRouteNames;
  createPreset: OperationalDashboardRouteNames;
  applyPreset: OperationalDashboardRouteNames;
}

export const overviewOperationalDashboardRouteNames: Record<
  AssetType,
  OverviewOperationalDashboardRouteName | undefined
> = {
  [AssetType.TippingVehicle]: {
    all: OperationalDashboardRouteNames.TippingVehicles,
    single: OperationalDashboardRouteNames.TippingVehicleById,
    createPreset:
      OperationalDashboardRouteNames.OperationalDashboardTippingVehicleCreatePreset,
    applyPreset:
      OperationalDashboardRouteNames.OperationalDashboardTippingVehicleApplyPreset,
  },
  [AssetType.MobileCompactor]: undefined,
  [AssetType.StaticCompactor]: undefined,
  [AssetType.AlbaStaticCompactor]: undefined,
  [AssetType.RefuseCollectionVehicle]: undefined,
  [AssetType.GenericAsset]: undefined,
  [AssetType.Crane]: undefined,
  [AssetType.WasteBin]: undefined,
  [AssetType.SkipLoader]: undefined,
  [AssetType.WasteCollTruck]: undefined,
  [AssetType.All]: undefined,
};

const operationalDashboardRoutes: RouteConfig = {
  path: '/operational-dashboard',
  component: Layout,
  redirect: '/operational-dashboard/tipping-vehicles',
  name: OperationalDashboardRouteNames.OperationalDashboard,
  meta: {
    title: 'route.operationalDashboard',
    icon: 'maintenance',
    alwaysShow: true,
    code: ALL_CLAIMS_CODES.AUTHRSC_MOD_OPER_DASHBOARD,
  },
  children: [
    {
      path: 'tipping-vehicles',
      component: () => import('@/views/support/OperationalDashboard.vue'),
      name: OperationalDashboardRouteNames.TippingVehicles,
      meta: {
        title: 'route.operationalDashboardTippingVehicles',
        claim: ALL_CLAIMS_CODES.AUTHRSC_MOD_OPER_TIPPING_VEHICLES,
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_OPER_TIPPING_VEHICLES_HOME,
        context: ContextType.SelectedCustomer,
      },
    },
    {
      path: 'tipping-vehicles/:id',
      component: () =>
        import(/* webpackChunkName: "assets" */ '@/views/assets/Asset.vue'),
      name: OperationalDashboardRouteNames.TippingVehicleById,
      props: true,
      meta: {
        title: 'route.operationalDashboardTippingVehicles',
        thirdLevelMenu: true,
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_OPER_TIPPING_VEHICLES,
        hidden: true,
        assetSpecificRoute: true,
      },
    },
    {
      path: 'tipping-vehicles/:id/preset/create',
      component: () =>
        import(
          /* webpackChunkName: "presetCreate" */ '@/views/preset/CreatePreset.vue'
        ),
      name: OperationalDashboardRouteNames.OperationalDashboardTippingVehicleCreatePreset,
      meta: {
        title: 'preset.create',
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_TIPPING_VEHICLES,
        assetType: AssetType.TippingVehicle,
        hidden: true,
        assetSpecificRoute: true,
      },
    },
    {
      path: 'tipping-vehicles/:id/preset/apply',
      component: () =>
        import(
          /* webpackChunkName: "presetCreate" */ '@/views/preset/ApplyPreset.vue'
        ),
      name: OperationalDashboardRouteNames.OperationalDashboardTippingVehicleApplyPreset,
      meta: {
        title: 'preset.apply',
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_TIPPING_VEHICLES,
        assetType: AssetType.TippingVehicle,
        hidden: true,
        assetSpecificRoute: true,
      },
    },
  ],
};

export default operationalDashboardRoutes;
