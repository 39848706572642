import { UUID } from '@/api/common';
import {
  getCustomersByAccessibleAssets,
  getCustomersByAssetServiceRegion,
} from '@/api/customerEndpoints';
import {
  fetchOrgById,
  getEveryOrganization,
  Organization,
} from '@/api/organizations';
import { AsyncValue, useAsync } from '@/composables/async';
import { CustomerSummary, mapOrgsToCustomerSummary } from '@/utils/org';
import { distinct } from '@/utils/ref';
import { ACTIVATION_STATUS, COMPANY_TYPE } from '@/utils/workData/lookuptable';
import {
  injectLocal,
  provideLocal,
  StorageSerializers,
  useSessionStorage,
} from '@vueuse/core';
import { computed, InjectionKey, Ref, unref, watch, watchEffect } from 'vue';
import { Claims } from './claims';
import { LoggedInUser, useLoggedInUser } from './user';

/**
 * Provide context to the application about a specific customer (and optionally
 * a subset of its organization tree) being selected for performing queries.
 *
 * If only a customer ID is selected, or a customer ID and its primary organization ID,
 * `SelectedCustomer` will indicate that a 'full customer' is selected.
 * I.e. no filtering on specific organizations within that customer will then be
 * necessary (`isSubOrgSelection` will be `false` in this case).
 *
 * If a customer ID is selected, and a *sub*-organization ID of that customer,
 * `SelectedCustomer` will indicate that the customer is selected, but within
 * that customer only information belonging to the selected sub-organization and
 * its lower level organizations is to be retrieved/shown.
 *
 * This mechanism is generic for Hyva Admin (notably Operational Support),
 * Designated Users (Helpdesk, BB, OEM, etc.), but due to historical reasons,
 * as of 2024-05-29, organization IDs are not yet fetched for specific (logged in)
 * company types. For these cases, only whole-company selection is supported for
 * the time being (i.e. `isSubOrgSelection` will always be false then).
 * This behavior is likely to change in the future, so any 'consumers' of
 * `SelectedCustomer` are encouraged to already support using `organizationId`
 * and `organizationIds` fields when `isSubOrgSelection` is true.
 *
 * As of 2024-05-29, this mechanism only applies to Hyva Admin and other
 * Designated users. Sub-organization selection for 'normal' customers is
 * still handled separately, but these mechanisms might be merged later.
 */
export interface SelectedCustomer {
  /**
   * Selected customerId, if any.
   *
   * If no customer is selected, `customerId` will be `undefined`.
   * Whether this means data for all customers can be retrieved is
   * determined by the `canImpersonate` vs `mustImpersonate` flags
   * on {@link LoggedInUser}.
   */
  readonly customerId: UUID | undefined;

  /**
   * Whether the selection specifies a subset of the organizations
   * of a customer (`true`), or anything of the selected customer.
   *
   * This allows for more efficient querying in certain endpoints,
   * as it may be cheaper to fetch all data of a specific customer ID,
   * instead of fetching data of a list of organization IDs.
   */
  readonly isSubOrgSelection: boolean;

  /**
   * Selected organization within customer.
   *
   * If `isSubOrgSelection` is true, this will be the primary organization
   * ID, otherwise the selected organization ID.
   *
   * Note: for historical reasons, this information may not (yet) be
   * available for all company types (e.g. Helpdesk, BB, OEM).
   * In this case, `isSubOrgSelection` will never be `true`.
   * (And conversely, if `isSubOrgSelection` is true, this information
   * will be present.)
   */
  readonly organization: Organization | undefined;
}

/**
 * Provide context to the application about a specific customer (and optionally
 * a subset of its organization tree) being selected for performing queries.
 *
 * See {@link SelectedCustomer} for more information.
 */
export interface SelectedCustomerInfo {
  /**
   * Provide context to the application about a specific customer (and optionally
   * a subset of its organization tree) being selected for performing queries.
   *
   * See {@link SelectedCustomer} for more information.
   */
  readonly selectedCustomer: Ref<SelectedCustomer | undefined>;

  /**
   * True while supporting information (customer / organization hierarchy)
   * is being loaded.
   */
  readonly isInitializingSelectedCustomer: Ref<boolean>;

  /**
   * List of companies and their organizations, accessible to the currently
   * logged-in user.
   *
   * Note: for historical reasons, (sub-)organization information might
   * not yet be loaded for certain (logged-in) company types.
   */
  readonly companies: Ref<AsyncValue<CustomerSummary[] | undefined>>;

  /**
   * Select a customer and optionally one of its (sub-)organizations.
   *
   * For `subOrganizationId`, passing a customer's primaryOrganizationId
   * is equivalent to passing `undefined`, and indicates that any of the
   * information of the customer is to be fetched/shown.
   * Otherwise, only information for that customer's sub-organization,
   * and any of its lower level organizations, is to be fetched/shown.
   *
   * @see {@link SelectedCustomer} for more information.
   */
  readonly updateSelectedCustomer: (
    newCustomerId: UUID | undefined,
    subOrganizationId?: UUID
  ) => Promise<void>;
}

interface Selection {
  companyId: UUID | undefined;
  organizationId: UUID | undefined;
}

const selectedCustomerInfoKey: InjectionKey<SelectedCustomerInfo> = Symbol(
  'selectedCustomerInfo'
);
const selectedCustomerStorageKey = 'selectedCustomer';

export function provideSelectedCustomerInfo(): void {
  const loggedInUser = useLoggedInUser();

  const companies = useAsync(
    computed(() => {
      if (!loggedInUser.value || !loggedInUser.value.canImpersonate) {
        return undefined;
      }
      return fetchCompanies(loggedInUser.value);
    })
  );

  const selectionRef = useSessionStorage<Selection | null>(
    selectedCustomerStorageKey,
    null, // Note: `undefined` is handled incorrectly
    { serializer: StorageSerializers.object }
  );

  const selectedOrganizationId = distinct((): UUID | undefined => {
    const selection = selectionRef.value;
    const selectedCustomerId = selection?.companyId;
    if (!selectedCustomerId) {
      return undefined;
    }

    const selectedCustomer = companies.value.data?.find(
      (customer) => customer.customerId === selectedCustomerId
    );
    if (!selectedCustomer) {
      return undefined;
    }

    return selection.organizationId ?? selectedCustomer.primaryOrganizationId;
  });

  const asyncSelectedOrganization = useAsync(
    computed(async (): Promise<Organization | undefined> => {
      const selectedCustomerId = selectionRef.value?.companyId;
      if (!selectedCustomerId || !selectedOrganizationId.value) {
        return undefined;
      }
      return fetchOrgById(selectedOrganizationId.value, {
        // TODO See if we can get a more minimalist ActiveContext instead
        impersonatedCompanyId: loggedInUser.value?.mustImpersonate
          ? selectedCustomerId
          : undefined,
        selectedCompanyId: selectedCustomerId,
        isSubOrgSelection: false,
        claims: new Claims([]),
        organization: undefined,
        organizationIds: [],
        primaryOrgTimeZone: '',
      });
    }),
    { clearOnRefresh: true }
  );

  // Select first available company on initial load
  const stopInitialSelection = watchEffect(() => {
    const selected = unref(selectionRef);
    const loadedCompanies = unref(companies).data;

    const isSessionStorageSelectionEmptyAndCompaniesFetched =
      selected === null &&
      loadedCompanies?.length &&
      loggedInUser.value?.mustImpersonate;

    // Made for the dev environment basically. If someone switches between two designated users
    // they might not have access to the company in session storage.
    const isSessionStorageSelectionNotInAvailableCompanies =
      selected !== null &&
      loadedCompanies !== undefined &&
      !loadedCompanies.some(
        (company) => company.customerId === selected.companyId
      );

    if (
      isSessionStorageSelectionEmptyAndCompaniesFetched ||
      isSessionStorageSelectionNotInAvailableCompanies
    ) {
      // Select the first company in the list by default upon initial load, then
      // stop this effect from running again.
      const firstCompany = loadedCompanies[0];
      selectionRef.value = {
        companyId: firstCompany.customerId,
        organizationId: undefined,
      };
      stopInitialSelection();
    }
  });

  // Unset selectedCustomer if logged in user isn't a designated user
  // (can happen when first logging in as designated user, logging out,
  // then logging in a different user in same browser)
  watchEffect(() => {
    if (loggedInUser.value?.canImpersonate === false && selectionRef.value) {
      selectionRef.value = null;
    }
  });

  const selectedCustomer = distinct((): SelectedCustomer | undefined => {
    const selectedOrg = asyncSelectedOrganization.value.data;
    if (!selectedOrg) {
      return undefined;
    }
    return {
      organization: selectedOrg,
      customerId: selectedOrg.companyId,
      isSubOrgSelection: selectedOrg.parentId !== undefined,
    };
  });

  const isInitializingSelectedCustomer = distinct((): boolean => {
    // If we don't even have a logged-in user, we don't know
    // what to do yet.
    if (!loggedInUser.value) {
      return true;
    }
    // If we have a selection, then let's just wait until
    // the customer's info has been loaded and is made available
    // for others to use.
    // No need to wait for the full list of companies to be loaded.
    if (selectionRef.value?.companyId || selectionRef.value?.organizationId) {
      return selectedCustomer.value === undefined;
    }
    // If we don't have a selection, we first need to wait until
    // all companies have been loaded, such that we can select
    // the first company.
    // The list may be empty though, in case we end up without a
    // selection, which is fine.
    return companies.value.loading;
  });

  function updateSelectedCustomer(
    newCustomerId: UUID | undefined,
    subOrganizationId?: UUID
  ): Promise<void> {
    selectionRef.value = {
      companyId: newCustomerId,
      organizationId: subOrganizationId,
    };
    return new Promise<void>((resolve, reject) => {
      const stopWatching = watch(asyncSelectedOrganization, (asyncOrg) => {
        if (asyncOrg.loading) {
          return;
        }
        if (asyncOrg.error) {
          reject(asyncOrg.error);
        } else {
          resolve();
        }
        stopWatching();
      });
    });
  }

  provideLocal(selectedCustomerInfoKey, {
    companies,
    isInitializingSelectedCustomer,
    selectedCustomer,
    updateSelectedCustomer,
  });
}

export function useSelectedCustomerInfo(): SelectedCustomerInfo {
  const info = injectLocal(selectedCustomerInfoKey);
  if (!info) {
    throw new Error(
      'No SelectedCustomerInfo state provided, use `provideSelectedCustomerInfo()` somewhere near the root of the app'
    );
  }
  return info;
}

async function fetchHelpdeskCompanies(
  _user: LoggedInUser
): Promise<CustomerSummary[]> {
  const customersResponse = await getCustomersByAssetServiceRegion(
    [],
    1,
    10000
  );

  return customersResponse.data.map(
    (customer): CustomerSummary => ({
      customerId: customer.id,
      customerName: customer.name,
      primaryOrganizationId: customer.primaryOrganizationId,
      primaryOrganization: undefined,
    })
  );
}

async function fetchBBDealerCompanies(
  user: LoggedInUser
): Promise<CustomerSummary[]> {
  const response = await getCustomersByAccessibleAssets(
    user.organization.id,
    1,
    10000
  );

  return response.data.map(
    (customer): CustomerSummary => ({
      customerId: customer.id,
      customerName: customer.name,
      primaryOrganizationId: customer.primaryOrganizationId,
      primaryOrganization: undefined,
    })
  );
}

async function fetchHyvaAdminCompanies(): Promise<CustomerSummary[]> {
  const allOrgs = await getEveryOrganization({
    activationStatus: ACTIVATION_STATUS.Activated,
  });

  const companies = mapOrgsToCustomerSummary(allOrgs);

  // Due to some (broken) historical data, it might be that we have
  // some organizations without a parent, all indicating that
  // they belong to the same company.
  const deduplicatedCompanies = new Map(
    companies.map((company) => [company.customerId, company])
  );
  return [...deduplicatedCompanies.values()];
}

async function fetchCompanies(
  loggedInUser: LoggedInUser
): Promise<CustomerSummary[] | undefined> {
  switch (loggedInUser.companyType) {
    case COMPANY_TYPE.Hyva:
      return fetchHyvaAdminCompanies();
    case COMPANY_TYPE.Helpdesk:
      return fetchHelpdeskCompanies(loggedInUser);
    default:
      return fetchBBDealerCompanies(loggedInUser);
  }
}
